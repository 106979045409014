import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';
import Form from './components/Form';
import Camera from './components/Camera'
import './App.css';

interface CameraImage {
  url: string;
  name: string;
}

function App() {
  const fetchCameras = async (): Promise<string[] | null> => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/cameras`, {
        headers: {
          Authorization: `Bearer ${token ? JSON.parse(token) : ''}`,
        },
      });
	  console.log(response)

      return response.data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return null;
    }
  };

  const fetchLatestImage = async (itemName: string): Promise<{ url: string } | null> => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/cameras/${itemName}/images/latest`, {
        headers: {
          Authorization: `Bearer ${token ? JSON.parse(token) : ''}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching data for', itemName, error);
      return null;
    }
  };

  const [data, setData] = useState<CameraImage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cameraList = await fetchCameras();
        if (!cameraList) throw new Error("Failed to fetch camera list");
        const cameraImages: CameraImage[] = [];

        for (const cameraName of cameraList) {
          const imgData = await fetchLatestImage(cameraName);
          if (imgData) {
            cameraImages.push({
              url: atob(imgData.url),
              name: cameraName,
            });
          }
        }

        setData(cameraImages);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

	// refresh cameras every minute
  	const intervalId = setInterval(() => {
		fetchData();
  	}, 30000);

  	return () => clearInterval(intervalId);}, []);

  if (loading) return <p>Loading...</p>;
  if (error) return (
	<>
	  	<p>Error: {error.message}</p>
		<Form />
	</>
);

  return (
    <>
	  {data.map((item, index) => (
          <div key={index}>
            <Camera alt={item.name} src={item.url} />
          </div>
        ))}
    </>
  );
}

export default App;
